import $ from 'jquery'

class Filters {
  static setupAll() {
    $('.filter').each((index, element) => new Filters(element))
  }
  constructor(selector) {
    this.municipio = this.getParams('municipio') ? this.getParams('municipio') : ''
    this.candidatura = this.getParams('candidatura') ? this.getParams('candidatura') : ''
    this.order = this.getParams('order') ? this.getParams('order') : ''
    this.container = $(selector)

    if ( typeof this.municipio !== "undefined" || this.municipio !==  undefined ) {
      const text = $('.filter-municipio').find(`[value="${this.municipio}"]`).text()      
      if(text === "") {
        $('.filter-municipio .filter-header span').text('Município')
      } else {
        $('.filter-municipio .filter-header span').text(text)
      }
    }

    if ( typeof this.candidatura !==  "undefined" || this.candidatura !==  undefined ) {
      const text = $('.filter-candidatura').find(`[value="${this.candidatura}"]`).text()      
      if(text === "") {
        $('.filter-candidatura .filter-header span').text('Candidatura')
      } else {
        $('.filter-candidatura .filter-header span').text(text)
      }
    }

    if ( typeof this.order !==  "undefined" || this.order !==  undefined ) {
      const text = $('.filter-order').find(`[value="${this.order}"]`).text()  
      console.log(text)    
      if(text === "") {
        $('.filter-order .filter-header span').text('Ordenar candidatas:')
      } else {
        if(this.order == 'ASC' || this.order == 'DESC') {
          $('.filter-order .filter-header span').text(`Ordenar candidatas: ${text}`)
        } else {
          $('.filter-order .filter-header span').text(text)
        }
      }
    }

    this.container.find('.filter-item').click((e) => this.onChange(e))
    this.container.find('.filter-header').click((e) => this.onClick(e))
  }
	getParams(key){
    var params = {};
    window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,
    function(str,key,value){
      params[key] = value
    });
    return key?params[key]:params
  }

  onClick (e) {
    const el = e.currentTarget
    const has = $(el).hasClass('open')

    if(has) {
      $(el).removeClass('open')
      $(el).parent().removeClass('open')
    } else {
      $(el).addClass('open')
      $(el).parent().addClass('open')
    }
  }

  onChange(e) {
    const selected = e.currentTarget.getAttribute('value')
    const type = e.currentTarget.getAttribute('category')
    $('.filter-header').removeClass('open')
    $('.filter').removeClass('open')

    history.pushState({}, '', `?municipio=${type == 'municipio' ? selected : this.municipio }&candidatura=${type == 'candidatura' ? selected : this.candidatura}&order=${type == 'order' ? selected : this.order}`)

    // Reload
    location.reload()
  }
}
export default Filters