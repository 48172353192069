import $ from 'jquery'
import './scss/style.scss'

import slick from 'slick-carousel'
import 'slick-carousel/slick/slick.css'

import Filters from './js/filters'

// Filter
Filters.setupAll();

$('.search-page').parent().find('.header').addClass('search-header')

// Slick Carousel
$('.banner-carousel').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  fade: true,
  cssEase: 'ease-in-out',
  arrows: false,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: false
})

$('.programa-carousel').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  fade: true,
  cssEase: 'ease-in-out',
  arrows: true,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true
})

// Click tab
$('.btn-tab').click(function(){
  const idx = $(this).attr('index')

  $('.btn-tab').removeClass('current')
  $(this).addClass('current')

  $('.tab').removeClass('current')
  $(`.tab-${idx}`).addClass('current')
})

// Open and close menu
$('.menu-btn').click(function(){
  const has = $(this).hasClass('open')
  if(has) {
    $(this).removeClass('open')
    $('.menu').removeClass('open')
  } else {
    $(this).addClass('open')
    $('.menu').addClass('open')
  }
})